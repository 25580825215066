// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { addColor, editColor, delColor, getAllColors } from '../services/color'

export const getAllColorsList = createAsyncThunk('colorReducer/getAllStates', async params => {
    const response = await getAllColors(params)
    return { data: response.data, count: response.data?.length, loading: false, params }
})

export const addColorData = createAsyncThunk('colorReducer/addColor', async data => {
    const response = await addColor(data)
    return { data: response.data, count: response?.data?.count, loading: false, message: response?.message }
})
export const editColorData = createAsyncThunk('colorReducer/editColor', async data => {
  const response = await editColor(data)
  return { data: response.data, count: response?.data?.count, loading: false, message: response?.message }
})
export const delColorData = createAsyncThunk('colorReducer/delColor', async data => {
  const response = await delColor(data)
  return { data: response.data, count: response?.data?.count, loading: false, message: response?.message }
})


export const stateModelSlice = createSlice({
    name: 'colorModel',
    initialState: {
      colorModelList: [],
        total: 0,
        selectedColorModel: null,
        message: null,
        loading: false
    },
    reducers: {
        setSelectedColorModel(state, action) {
            state.selectedColorModel = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(getAllColorsList.pending, (state) => {
            state.loading = true

        })
        builder.addCase(getAllColorsList.fulfilled, (state, action) => {
            state.colorModelList = action.payload.data
            state.total = action.payload.count
            state.loading = false
        })
        builder.addCase(addColorData.fulfilled, (state, action) => {
            state.colorModelList = [...state.colorModelList, action.payload.data]
            state.selectedColorModel = action.payload.data
            state.message = action.payload?.message
            state.loading = false
        })
        builder.addCase(addColorData.rejected, (state, action) => {
          state.message = action.payload?.message
          state.loading = false
           
        })
        builder.addCase(editColorData.fulfilled, (state, action) => {
          for (const i in state.colorModelList) {
            if (state.colorModelList[i]._id === action.payload.data._id) {
                state.colorModelList[i].name = action.payload.data.name
                state.colorModelList[i].hexCode = action.payload.data.hexCode
                state.colorModelList[i].isActive = action.payload.data.isActive
                state.colorModelList[i].createdAt = action.payload.data.createdAt
                state.colorModelList[i].updatedAt = action.payload.data.updatedAt
                break
            }
        }
          state.selectedColorModel = action.payload.data
          state.message = action.payload?.message
          state.loading = false
           
        })
        builder.addCase(delColorData.pending, (state, action) => {
          state.message = action.payload?.message
          state.loading = true
           
        })
        builder.addCase(delColorData.fulfilled, (state, action) => {
          state.colorModelList = state.colorModelList.filter((item) => item._id !== action.payload.data._id)
          state.selectedColorModel = action.payload.data
          state.message = action.payload?.message
          state.loading = false
           
        })

    }
})

export const { setSelectedStateModel } = stateModelSlice.actions

export default stateModelSlice.reducer
