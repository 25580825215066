import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAllZoneTypes, addZoneType, editZoneType, delZoneType } from '../services/zoneType'

export const getAllZoneTypeList = createAsyncThunk('zoneType/getAllZoneTypeList', async params => {
    const response = await getAllZoneTypes(params)
    return { data: response.data, count: response.data?.length, loading: false, params }
})

export const createZoneType = createAsyncThunk('zoneType/createZoneType', async data => {
    const response = await addZoneType(data)
    console.log('create response', response)
    return { data, count: response?.data?.length, loading: false, message: response?.message }
})

export const updateZoneType = createAsyncThunk('zoneType/updateZoneType', async data => {
    console.log('updateZoneType', data)
    const response = await editZoneType(data)
    console.log('fgddfgdfgfdf', response)
    return { data, count: response?.data?.length, loading: false, message: response?.message }
})

export const deleteZoneType = createAsyncThunk('zoneType/deleteZoneType', async data => {
    const response = await delZoneType(data)
    console.log('first', response)
    return { data, count: response?.data?.length, loading: false, message: response?.message }
})

export const zoneTypeSlice = createSlice({
    name: 'zoneType',
    initialState: {
        zoneTypeList: [],
        total: 0,
        selectedZone: null,
        message: null,
        loading: false
    },
    reducers: {
        setSelectedZone(state, action) {
            state.selectedZone = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(getAllZoneTypeList.pending, (state) => {
            state.loading = true

        })
        builder.addCase(getAllZoneTypeList.fulfilled, (state, action) => {
            state.zoneTypeList = action.payload.data
            state.total = action.payload.count
            state.loading = false
        })
        builder.addCase(createZoneType.pending, (state, action) => {
            state.message = action.payload?.message
            state.loading = true
        })
        builder.addCase(createZoneType.fulfilled, (state, action) => {
            state.zoneTypeList = [...state.zoneTypeList, action.payload.data]
            state.message = action.payload?.message
            state.loading = false
        })
        builder.addCase(updateZoneType.pending, (state, action) => {
            state.message = action.payload?.message
            state.loading = true
        })
        builder.addCase(updateZoneType.fulfilled, (state, action) => {

            state.message = action.payload?.message
            state.loading = false
        })
        builder.addCase(deleteZoneType.pending, (state, action) => {
            state.message = action.payload?.message
            state.loading = true
        })
        builder.addCase(deleteZoneType.fulfilled, (state, action) => {
            state.zoneTypeList = state.zoneTypeList.filter(item => item._id !== action.payload.data._id)
            state.message = action.payload?.message
            state.loading = false
        })
        builder.addCase(deleteZoneType.rejected, (state, action) => {
            state.message = action.payload?.message
            state.loading = false
        })
    }   
})

export const { setSelectedZone } = zoneTypeSlice.actions

export default zoneTypeSlice.reducer