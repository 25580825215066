import axios from 'axios'

export const baseUrl = process.env.REACT_APP_API_BASE_URL

export const apiInstant = axios.create({
    baseURL: baseUrl
})

// Add a request interceptor
apiInstant.interceptors.request.use(function (config) {
    const token = JSON.parse(localStorage.getItem('token'))
    config.headers.Authorization = token ? `Bearer ${token}` : null
    return config
}, function (error) {
    return Promise.reject(error)
})

// Add a response interceptor
apiInstant.interceptors.response.use(function (response) {
    // console.log(config)
    return response
}, function (error) {
    return Promise.reject(error)
})