import { baseApiCall } from "."

export const getVehicleMakers = (params) => {
    return baseApiCall({
        url: "vehicleMaker",
        method: "get",
        params
    })
}

export const createVehicleMakers = (data) => {
    return baseApiCall({
        url: "vehicleMaker",
        method: "post",
        data
    })
}

export const delVehicleMaker = (data) => {
    return baseApiCall({
        url: "vehicleMaker",
        method: "delete",
        data
    })
}

export const editVehicleMake = (data) => {
    return baseApiCall({
        url: "vehicleMaker",
        method: "put",
        data
    })
}

