import { baseApiCall } from "."

export const getUserRepos = (params) => {
    return baseApiCall({
        url: "user/repository",
        method: "get",
        params
    })
}

export const notifyUser = (data) => {
    return baseApiCall({
        url: "notification/user",
        method: "post",
        data
    })
}
export const notifyMultiUser = (data) => {
    return baseApiCall({
        url: "notification/user/multicast",
        method: "post",
        data
    })
}