// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getVehicleModels, addVehicleModels } from '../services/vehicleModels'

export const getVehicleModelList = createAsyncThunk('vehicleModel/getVehicleModel', async params => {
    const response = await getVehicleModels(params)
    return { data: response.data, count: response.data?.length, loading: false, params }
})

export const addVehicleModelData = createAsyncThunk('vehicleModel/addVehicleModelData', async data => {
    const response = await addVehicleModels(data)
    return { data: response.data,  loading: false, message: response?.message }
})

export const vehicleModelSlice = createSlice({
    name: 'vehicleModel',
    initialState: {
        vehicleModelList: [],
        total: 0,
        selectedVehicleModel: null,
        message: null,
        loading: false
    },
    reducers: {
        setSelectedVehicleModel(state, action) {
            state.selectedVehicleModel = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(getVehicleModelList.pending, (state) => {
            state.loading = true

        })
        builder.addCase(getVehicleModelList.fulfilled, (state, action) => {
            state.vehicleModelList = action.payload.data
            state.total = action.payload.count
            state.loading = false
        })
        builder.addCase(addVehicleModelData.pending, (state) => {
            state.loading = true

        })
        builder.addCase(addVehicleModelData.fulfilled, (state, action) => {
            state.vehicleModelList = [...state.vehicleModelList, action.payload.data] 
            state.selectedVehicleModel = action.payload.data
            state.message = action.payload?.message
            state.total =  state.total + 1
            state.loading = false
        })
        builder.addCase(addVehicleModelData.rejected, (state, action) => {
            state.message = action.payload?.message
            state.loading = false
           
        })

    }
})

export const { setSelectedVehicleModel } = vehicleModelSlice.actions

export default vehicleModelSlice.reducer
