import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAllConfig, addAdminSettings, addAppSettings, addServiceSettings, riderReferral, driverReferral  } from '../services/globalSettings'

export const getAllConfigData = createAsyncThunk('globalSettings/getAllConfigData', async params => {

    const response = await getAllConfig(params)

    return { data: response.data, loading: false, params, message: response?.message }
})

export const addAdminData = createAsyncThunk('globalSettings/addAdminData', async data => {
    const response = await addAdminSettings(data)
    return { data: response.data, loading: false, message: response?.message }

})
export const addAppData = createAsyncThunk('globalSettings/addAppData', async data => {
    const response = await addAppSettings(data)
    return { data: response.data, loading: false, message: response?.message }

})
export const addServiceData = createAsyncThunk('globalSettings/addServiceData', async data => {
    const response = await addServiceSettings(data)
    return { data: response.data, loading: false, message: response?.message }

})
export const riderReferralData = createAsyncThunk('globalSettings/riderReferralData', async data => {
    const response = await riderReferral(data)
    return { data: response.data, loading: false, message: response?.message }
})
export const driverReferralData = createAsyncThunk('globalSettings/driverReferralData', async data => {
    const response = await driverReferral(data)
    return { data: response.data, loading: false, message: response?.message }
})


export const globalSettingsModelSlice = createSlice({
    name: 'globalSettingsState',
    initialState: {
        globalSettingsModel: {},
        message: null,
        loading: false
    },
        extraReducers: builder => {
        builder.addCase(getAllConfigData.pending, (state) => {
            state.loading = true

        })
        builder.addCase(getAllConfigData.fulfilled, (state, action) => {
            state.globalSettingsModel = action.payload.data
            state.loading = false
        })
        builder.addCase(addAdminData.pending, (state, action) => {
            state.message = action.payload?.message
            state.loading = true
        })
        builder.addCase(addAdminData.fulfilled, (state, action) => {
            state.globalSettingsModel = action.payload.data
            state.message = action.payload?.message
            state.loading = false
        })
        builder.addCase(addAdminData.rejected, (state, action) => {
            state.message = action.payload?.message
            state.loading = false
        })
        builder.addCase(addAppData.pending, (state, action) => {
            state.message = action.payload?.message
            state.loading = true
        })
        builder.addCase(addAppData.fulfilled, (state, action) => {
            state.globalSettingsModel = action.payload.data
            state.message = action.payload?.message
            state.loading = false
        })
        builder.addCase(addAppData.rejected, (state, action) => {
            state.message = action.payload?.message
            state.loading = false
        })
        builder.addCase(addServiceData.pending, (state, action) => {
            state.message = action.payload?.message
            state.loading = true
        })
        builder.addCase(addServiceData.fulfilled, (state, action) => {
            state.globalSettingsModel = action.payload.data
            state.message = action.payload?.message
            state.loading = false
        })
        builder.addCase(addServiceData.rejected, (state, action) => {
            state.message = action.payload?.message
            state.loading = false
        })  
        builder.addCase(riderReferralData.pending, (state, action) => {
            state.message = action.payload?.message
            state.loading = true
        })
        builder.addCase(riderReferralData.fulfilled, (state, action) => {
            state.globalSettingsModel = action.payload.data
            state.message = action.payload?.message
            state.loading = false
        })
        builder.addCase(riderReferralData.rejected, (state, action) => {
            state.message = action.payload?.message
            state.loading = false
        })
        builder.addCase(driverReferralData.pending, (state, action) => {
            state.message = action.payload?.message
            state.loading = true
        })
        builder.addCase(driverReferralData.fulfilled, (state, action) => {
            state.globalSettingsModel = action.payload.data
            state.message = action.payload?.message
            state.loading = false
        })
        builder.addCase(driverReferralData.rejected, (state, action) => {
            state.message = action.payload?.message
            state.loading = false
        })

    }
})

export default globalSettingsModelSlice.reducer

