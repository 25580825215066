import { baseApiCall } from "."

export const fetchDriver = (params) => {
    return baseApiCall({
        url: "admin/drivers",
        method: "get",
        params
    })
}

export const fetchDriverDetails = (params) => {
    return baseApiCall({
        url: "admin/driver",
        method: "get",
        params
    })
}

export const updateDriverInfo = (data) => {
    return baseApiCall({
        url: "admin/driver",
        method: "post",
        data
    })
}

export const updateDriverDocStatus = (data) => {
    return baseApiCall({
        url: "admin/driver/doc-status",
        method: "post",
        data
    })
}

export const submitReportToCheckr = (data) => {
    return baseApiCall({
        url: "checkr/candidates",
        method: "post",
        data
    })
}

export const updateDriverStatus = (data) => {
    return baseApiCall({
        url: "admin/driver/status",
        method: "post",
        data
    })
}