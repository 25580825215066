import { baseApiCall } from "."


export const getUserFee = (params) => {
    return baseApiCall({
        url: "user-service",
        method: "get",
        params
    })
}

export const addUserFee = (data) => {
    return baseApiCall({
        url: "user-service",
        method: "post",
        data
    })
}

