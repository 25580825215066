import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getVehicleMakers, createVehicleMakers, delVehicleMaker, editVehicleMake } from '../services/vehicleMaker'


export const getVehicleList = createAsyncThunk('vehicleMaker/getList', async params => {
    const response = await getVehicleMakers(params)
    return { data: response.data, count: response.data?.length, loading: false, params }
})

export const addVehicleMaker = createAsyncThunk('vehicleMaker/createVehicle', async data => {
    const response = await createVehicleMakers(data)
    return { data: response.data, count: response.data.count }
})

export const updateVehicleMake = createAsyncThunk('vehicleMaker/updateVehicle', async data => {
    const response = await editVehicleMake(data)
    return { data: response.data, loading: false }
})

export const deleteVehicleMake = createAsyncThunk('vehicleMaker/deleteMake', async data => {
    const response = await delVehicleMaker(data)
    return { data: response.data, loading: false }
})

export const vehicleMakerSlice = createSlice({
    name: 'vehicleMaker',
    initialState: {
        vehicleMakerList: [],
        total: 0,
        loading: false,
        selectedVehicleMaker: null
    },
    reducers: {
        setSelectedVehicleMaker(state, action) {
            state.selectedVehicleMaker = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(getVehicleList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getVehicleList.fulfilled, (state, action) => {
            state.vehicleMakerList = action.payload.data
            state.total = action.payload.count
            state.loading = false
        })
        builder.addCase(addVehicleMaker.fulfilled, (state, action) => {
            state.vehicleMakerList = [...state.vehicleMakerList, action.payload.data]
            state.selectedVehicleMaker = action.payload.data
            state.total = state.total + 1
            state.loading = false
        })
        
        builder.addCase(addVehicleMaker.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(updateVehicleMake.pending, (state) => {
            state.loading = false
        })
        builder.addCase(updateVehicleMake.fulfilled, (state, action) => {
            for (const i in state.vehicleMakerList) {
                if (state.vehicleMakerList[i]._id === action.payload.data._id) {
                    state.vehicleMakerList[i].name = action.payload.data.name
                    break
                }
            }

            state.selectedVehicleMaker = action.payload.data
            state.loading = false
        })
        builder.addCase(updateVehicleMake.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(deleteVehicleMake.pending, (state) => {
            state.loading = true
        })
        builder.addCase(deleteVehicleMake.fulfilled, (state, action) => {
            state.vehicleMakerList = state.vehicleMakerList.filter(item => action.payload.data._id !== item._id)
            state.selectedVehicleMaker = action.payload.data
            state.total = state.total - 1
            state.loading = false
        })
        builder.addCase(deleteVehicleMake.rejected, (state) => {
            state.loading = false
        })

    }
})

export const { setSelectedVehicleMaker } = vehicleMakerSlice.actions

export default vehicleMakerSlice.reducer
