import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAutoSurge, addStreakReward, getStreakReward, updateStreakReward, assignSurgeValue, getAssignSurge } from '../services/autoSurge'

export const getAllAutoSurge = createAsyncThunk('autoSurge/getAllAutoSurge', async params => {
    const response = await getAutoSurge(params)
    return { data: response.data, count: response.data?.length, loading: false, params }
})
export const getAllStreakReward = createAsyncThunk('autoSurge/getAllStreakReward', async params => {
    const response = await getStreakReward(params)
    return { data: response.data, count: response.data?.length, loading: false, params }
})

export const addStreakSurge = createAsyncThunk('autoSurge/addStreakSurge', async data => {
    console.log('data in Reducer', data)
    const response = await addStreakReward(data)
    return { data: response.data, count: response.data?.length, loading: false, params }
})
export const updateStreakSurge = createAsyncThunk('autoSurge/updateStreakSurge', async data => {
    console.log('data in update Reducer XXXXXXXXXXXXXXXXXXXXXXXXXXXX', data)
    const response = await updateStreakReward(data)
    return { data: response.data, count: response.data?.length, loading: false, params }
})

export const assignSurge = createAsyncThunk('autoSurge/assignSurge', async data => {
    console.log('data in assignSurge Reducer', data)
    const response = await assignSurgeValue(data)
    return { data, count: response.data?.length, loading: false }
})
export const getAssignSurgeValue = createAsyncThunk('autoSurge/getAssignSurgeValue', async data => {
    console.log('data in getAssignSurgeValue Reducer', data)
    const response = await getAssignSurge(data)
    return { data: response.data, count: response.data?.length, loading: false }
})

export const autoSurgeSlice = createSlice({
    name: 'autoSurge',
    initialState: {
        autoSurgeList: [],
        streakRewardList: [],
        assignSurgeList: [],
        total: 0,
        selectedZone: null,
        message: null,
        loading: false
    },
    reducers: {
        setSelectedZone(state, action) {
            state.selectedZone = action.payload
        }
    },  
    extraReducers: builder => {
        builder.addCase(getAllAutoSurge.pending, (state) => {
            state.loading = true

        })
        builder.addCase(getAllAutoSurge.fulfilled, (state, action) => {
            state.autoSurgeList = action.payload.data
            state.total = action.payload.count
            state.loading = false
        })
        builder.addCase(getAllAutoSurge.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(addStreakSurge.pending, (state) => {
            state.loading = true

        })
        builder.addCase(addStreakSurge.fulfilled, (state, action) => {
            state.streakRewardList = [...state.streakRewardList, action.payload.data]
            state.loading = false
        })
        builder.addCase(addStreakSurge.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(getAllStreakReward.pending, (state) => {
            state.loading = true

        })
        builder.addCase(getAllStreakReward.fulfilled, (state, action) => {
            state.streakRewardList = action.payload.data
        })
        builder.addCase(getAllStreakReward.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(updateStreakSurge.pending, (state) => {
            state.loading = true

        })
        builder.addCase(updateStreakSurge.fulfilled, (state, action) => {
            for (let i = 0; i < state.streakRewardList.length; i++) {
                if (state.streakRewardList[i]._id === action.payload.data._id) {
                    state.streakRewardList[i] = action.payload.data
                }
            }   
            state.loading = false

        })
        builder.addCase(updateStreakSurge.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(assignSurge.pending, (state) => {
            state.loading = true

        })
        builder.addCase(assignSurge.fulfilled, (state, action) => {
            for (let i = 0; i < state.assignSurgeList.length; i++) {
                if (state.assignSurgeList[i]._id === action.payload.data._id) {
                    state.assignSurgeList[i] = action.payload.data
                }
            }
            state.loading = false
        })
        builder.addCase(assignSurge.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(getAssignSurgeValue.pending, (state) => {
            state.loading = true

        })
        builder.addCase(getAssignSurgeValue.fulfilled, (state, action) => {
            state.assignSurgeList = action.payload.data
            state.loading = false
        })
        builder.addCase(getAssignSurgeValue.rejected, (state) => {
            state.loading = false
        })
        
    }   
})

export const { setSelectedZone } = autoSurgeSlice.actions

export default autoSurgeSlice.reducer

