import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'

const data = [
    {
        id: 1,
        customerName: "Max Fetherstonhaugh",
        rideDate: "8/30/2022",
        bookingId: "01GNEWGEJATK15C47661RQ0Z9J",
        driverName: "Gideon Barrim",
        serviceFare: "$31.90",
        surcharge: "$6.89",
        promoCode: "$6.44",
        rideStatus: "Completed",
        tip: "$147.61",
        tax: "$6.43",
        rideTotal: "$1467.34",
        driverCommission: "$66.17",
        adminCommission: "$129.91",
        driverPayout: true
      },
      {
        id: 2,
        customerName: "Remy Perl",
        rideDate: "4/17/2022",
        bookingId: "01GNEWGEJJD100ZAF7BBNK0BVX",
        driverName: "Rosy Judkin",
        serviceFare: "$41.42",
        surcharge: "$9.74",
        promoCode: "$5.68",
        rideStatus: "Completed",
        tip: "$159.78",
        tax: "$9.36",
        rideTotal: "$340.14",
        driverCommission: "$47.13",
        adminCommission: "$153.66",
        driverPayout: false
      },
      {
        id: 3,
        customerName: "Lockwood Plinck",
        rideDate: "4/29/2022",
        bookingId: "01GNEWGEJRF23XXDNB11AFP9R2",
        driverName: "Ferdinand Dominelli",
        serviceFare: "$31.09",
        surcharge: "$8.37",
        promoCode: "$5.82",
        rideStatus: "Completed",
        tip: "$138.11",
        tax: "$8.14",
        rideTotal: "$1222.58",
        driverCommission: "$172.73",
        adminCommission: "$45.25",
        driverPayout: true
      },
      {
        id: 4,
        customerName: "Delly Smithson",
        rideDate: "11/14/2022",
        bookingId: "01GNEWGEJZ8QX7M1SGZB0PQSEE",
        driverName: "Arie Pitkethly",
        serviceFare: "$44.37",
        surcharge: "$9.61",
        promoCode: "$9.50",
        rideStatus: "Completed",
        tip: "$39.01",
        tax: "$7.55",
        rideTotal: "$2615.35",
        driverCommission: "$11.46",
        adminCommission: "$164.10",
        driverPayout: false
      },
      {
        id: 5,
        customerName: "Osbourn Moline",
        rideDate: "6/1/2022",
        bookingId: "01GNEWGEK56EEJKGAW8HKWTEDN",
        driverName: "Emmie Heintzsch",
        serviceFare: "$40.71",
        surcharge: "$6.98",
        promoCode: "$5.84",
        rideStatus: "Completed",
        tip: "$164.08",
        tax: "$5.26",
        rideTotal: "$1943.15",
        driverCommission: "$27.24",
        adminCommission: "$10.17",
        driverPayout: false
      },
      {
        id: 6,
        customerName: "Miner Jereatt",
        rideDate: "10/14/2022",
        bookingId: "01GNEWGEKB4THFQMQC4RJH9966",
        driverName: "Jewell Ruse",
        serviceFare: "$49.05",
        surcharge: "$9.93",
        promoCode: "$8.66",
        rideStatus: "Completed",
        tip: "$73.99",
        tax: "$8.17",
        rideTotal: "$2833.71",
        driverCommission: "$138.25",
        adminCommission: "$164.05",
        driverPayout: true
      },
      {
        id: 7,
        customerName: "Lewiss Chadwen",
        rideDate: "8/29/2022",
        bookingId: "01GNEWGEKH7KS75KCJSBJHGWVV",
        driverName: "Renault Persich",
        serviceFare: "$30.97",
        surcharge: "$5.35",
        promoCode: "$6.62",
        rideStatus: "Completed",
        tip: "$60.09",
        tax: "$8.73",
        rideTotal: "$1346.67",
        driverCommission: "$148.77",
        adminCommission: "$125.76",
        driverPayout: true
      },
      {
        id: 8,
        customerName: "Jervis Moar",
        rideDate: "2/12/2022",
        bookingId: "01GNEWGEKQ9KJWAX55GK6448H2",
        driverName: "Maryanna Gowry",
        serviceFare: "$32.52",
        surcharge: "$9.33",
        promoCode: "$7.06",
        rideStatus: "Completed",
        tip: "$199.78",
        tax: "$9.81",
        rideTotal: "$1787.99",
        driverCommission: "$114.36",
        adminCommission: "$78.32",
        driverPayout: false
      },
      {
        id: 9,
        customerName: "Bernie McConnal",
        rideDate: "8/3/2022",
        bookingId: "01GNEWGEKXH7YQ30TTGTXB7EJ2",
        driverName: "Nita Bowen",
        serviceFare: "$31.86",
        surcharge: "$8.74",
        promoCode: "$5.26",
        rideStatus: "Completed",
        tip: "$14.55",
        tax: "$5.89",
        rideTotal: "$483.94",
        driverCommission: "$191.01",
        adminCommission: "$93.95",
        driverPayout: true
      },
      {
        id: 10,
        customerName: "Peyter Pasque",
        rideDate: "4/24/2022",
        bookingId: "01GNEWGEM34TXCHFVT88KETPSG",
        driverName: "Kamilah Fendlen",
        serviceFare: "$52.81",
        surcharge: "$8.18",
        promoCode: "$9.17",
        rideStatus: "Completed",
        tip: "$82.15",
        tax: "$5.33",
        rideTotal: "$2335.53",
        driverCommission: "$169.24",
        adminCommission: "$191.87",
        driverPayout: true
      }
]

mock.onGet('/api/datatables/earning-report').reply(() => {
  return [200, data]
})

mock.onGet('/api/datatables/data').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1 } = config
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.filter(
    item =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      item.customer.toLowerCase().includes(queryLowered) ||
      item.email.toLowerCase().includes(queryLowered) ||
      item.phone.toLowerCase().includes(queryLowered) ||
      item.vehicle.toLowerCase().includes(queryLowered) ||
      item.dropDateTime.toLowerCase().includes(queryLowered) ||
      item.pickupDateTime.toLowerCase().includes(queryLowered) ||
      item.bookingStatus.toLowerCase().includes(queryLowered) ||
      item.paymentStatus.toLowerCase().includes(queryLowered) ||
      item.pickupAddress.toLowerCase().includes(queryLowered) ||
      item.dropoffAddress.toLowerCase().includes(queryLowered)
  )
  /* eslint-enable  */

  return [
    200,
    {
      allData: data,
      invoices: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})
