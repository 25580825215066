// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAllPromos, addPromo, editPromo, delPromo  } from '../services/promoCode'

export const getAllPromoData = createAsyncThunk('promoCode/getAllPromo', async params => {

    const response = await getAllPromos(params)

    return { data: response.data, count: response.data?.length, loading: false, params, message: response?.message }
})

export const addPromoData = createAsyncThunk('promoCode/addAdvt', async data => {
    const response = await addPromo(data)
    return { data: response.data, count: response?.data?.count, loading: false, message: response?.message }
})
export const editPromoData = createAsyncThunk('promoCode/editAdvt', async data => {
    const response = await editPromo(data)
    return { data: response.data, count: response?.data?.count, loading: false, message: response?.message }
})
export const delPromoData = createAsyncThunk('promoCode/delAdvt', async data => {

    const response = await delPromo(data)

    return { data, count: response?.data?.count, loading: false, message: response?.message }
})


export const promoModelSlice = createSlice({
    name: 'promoModel',
    initialState: {
        promoCodeList: [],
        total: 0,
        selectedPromo: null,
        message: null,
        loading: false
    },
    reducers: {
        setSelectedAdvtModel(state, action) {
            state.selectedPromo = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(getAllPromoData.pending, (state) => {
            state.loading = true

        })
        builder.addCase(getAllPromoData.fulfilled, (state, action) => {
            state.promoCodeList = action.payload.data
            state.total = action.payload.count
            state.loading = false
        })
        builder.addCase(addPromoData.fulfilled, (state, action) => {
            state.promoCodeList = [...state.promoCodeList, action.payload.data]
            state.selectedPromo = action.payload.data
            state.message = action.payload?.message
            state.loading = false
        })
        builder.addCase(addPromoData.rejected, (state, action) => {
            state.message = action.payload?.message
            state.loading = false

        })
        builder.addCase(editPromoData.fulfilled, (state, action) => {
            for (const i in state.promoCodeList) {
                if (state.promoCodeList[i]._id === action.payload.data._id) {
                    state.promoCodeList[i].name = action.payload.data.name
                    state.promoCodeList[i].type = action.payload.data.type
                    state.promoCodeList[i].url = action.payload.data.url
                    state.promoCodeList[i].isActive = action.payload.data.isActive
                    state.promoCodeList[i].createdAt = action.payload.data.createdAt
                    state.promoCodeList[i].updatedAt = action.payload.data.updatedAt
                    break
                }
            }
            state.selectedPromo = action.payload.data
            state.message = action.payload?.message
            state.loading = false

        })
        builder.addCase(delPromoData.pending, (state, action) => {
            state.message = action.payload?.message
            state.loading = true

        })
        builder.addCase(delPromoData.fulfilled, (state, action) => {
            state.promoCodeList = state.promoCodeList.filter((item) => item._id !== action.payload.data._id)
            state.selectedPromo = action.payload.data
            state.message = action.payload?.message
            state.loading = false

        })

    }
})

export const { setSelectedPromo } = promoModelSlice.actions

export default promoModelSlice.reducer
