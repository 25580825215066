// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {  getUserFee, addUserFee } from '../services/userServiceFee'

export const getUseFeeData = createAsyncThunk('userFeeReducer/getUserFee', async params => {

    const response = await getUserFee(params)

    return { data: response.data, loading: false, params, message: response?.message }
})

export const addUseFeeData = createAsyncThunk('userFeeReducer/addUserFee', async data => {
    const response = await addUserFee(data)
    return { data: response.data, loading: false, message: response?.message }

})

export const userFeeModelSlice = createSlice({
    name: 'userFeeState',
    initialState: {
        userFeeModel: {},
        message: null,
        loading: false
    },

    extraReducers: builder => {
        builder.addCase(getUseFeeData.pending, (state) => {
            state.loading = true

        })
        builder.addCase(getUseFeeData.fulfilled, (state, action) => {
            state.userFeeModel = action.payload.data
            state.loading = false
        })
        builder.addCase(addUseFeeData.pending, (state, action) => {
            state.message = action.payload?.message
            state.loading = true
        })
        builder.addCase(addUseFeeData.fulfilled, (state, action) => {
            state.userFeeModel = action.payload.data
            state.message = action.payload?.message
            state.loading = false
        })
        builder.addCase(addUseFeeData.rejected, (state, action) => {
            state.message = action.payload?.message
            state.loading = false
        })
    }
})

export default userFeeModelSlice.reducer
