import { baseApiCall } from "."


export const getAllColors = (params) => {
    return baseApiCall({
        url: "color",
        method: "get",
        params
    })
}

export const addColor = (data) => {
    return baseApiCall({
        url: "color",
        method: "post",
        data
    })
}

export const editColor = (data) => {
    const {
       name,
       hexCode,
        _id
    } = data
    const editData = { name, hexCode}
    return baseApiCall({
        url: (`color/${_id}`),
        method: "put",
        data: editData
    })
}

export const delColor = (data) => {
    return baseApiCall({
        url: `color/${data._id}`,
        method: "delete",
        data
    })
}
