// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchDriver, fetchDriverDetails, submitReportToCheckr, updateDriverDocStatus, updateDriverInfo, updateDriverStatus } from '../services/driver'

export const getDriverList = createAsyncThunk('driver/getList', async params => {
    const response = await fetchDriver(params)
    console.log('getDriverList', response)
    return { data: response.data.data, count: response.data.count, params }
})

export const getDriverById = createAsyncThunk('driver/getDetails', async params => {
    const response = await fetchDriverDetails(params)
    return { data: response.data }
})

export const saveDriverInfo = createAsyncThunk('driver/saveInfo', async data => {
    const response = await updateDriverInfo(data)
    return { data: response.data }
})

export const saveDriverDocStatus = createAsyncThunk('driver/saveDocStatus', async data => {
    const response = await updateDriverDocStatus(data)
    return { data: response.data }
})

export const submitReport = createAsyncThunk('driver/submitReport', async data => {
    const response = await submitReportToCheckr(data)
    return { data: response.data }
})

export const changeDriverStatus = createAsyncThunk('driver/changeStatus', async data => {
    console.log('first', data)
    const response = await updateDriverStatus(data)
    return { data: response.data }
})

export const driverSlice = createSlice({
    name: 'driver',
    initialState: {
        driverList: [],
        total: 0,
        loading: false,
        selectedUser: null
    },
    reducers: {
        setSelectedUser(state, action) {
            state.selectedUser = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(getDriverList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getDriverList.fulfilled, (state, action) => {
            state.driverList = action.payload.data
            state.total = action.payload.count
            state.loading = false
        })
        builder.addCase(getDriverById.fulfilled, (state, action) => {
            state.selectedUser = action.payload.data
            state.loading = false
        })
        builder.addCase(saveDriverInfo.fulfilled, (state, action) => {
            state.selectedUser = action.payload.data
            state.loading = false
        })
        builder.addCase(saveDriverDocStatus.fulfilled, (state, action) => {
            state.selectedUser = action.payload.data
            state.loading = false
        })
        builder.addCase(submitReport.fulfilled, (state, action) => {
            state.selectedUser = action.payload.data
            state.loading = false
        })
        builder.addCase(changeDriverStatus.fulfilled, (state, action) => {
            state.selectedUser.status = action.meta.arg.status
        })
    }
})

export const { setSelectedUser } = driverSlice.actions

export default driverSlice.reducer
