// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAllRewards, addRewards, editRewards, delRewards } from '../services/rewards'

export const getAllRewardsList = createAsyncThunk('rewardsReducer/getAllRewardsList', async params => {
    const response = await getAllRewards(params)
    return { data: response.data, count: response.data?.length, loading: false, params }
})

export const addRewardsData = createAsyncThunk('rewardsReducer/addColor', async data => {

    const response = await addRewards(data)
    console.log('first response', response)
    return { data: response?.data?.entity, count: response?.data?.count, loading: false, message: response?.message }
})
export const editRewardsData = createAsyncThunk('rewardsReducer/editColor', async data => {
  const response = await editRewards(data)
  return { data: response.data, count: response?.data?.count, loading: false, message: response?.message }
})
export const delRewardsData = createAsyncThunk('rewardsReducer/delColor', async data => {
  const response = await delRewards(data)
  return { data: response.data, count: response?.data?.count, loading: false, message: response?.message }
})


export const rewardsModelSlice = createSlice({    
    name: 'rewardsModel',
    initialState: {
        rewardsModelList: [],
        total: 0,
        selectedRewardsModel: null,
        message: null,
        loading: false
    },
    reducers: {
        setSelectedRewardsModel(state, action) {
            state.selectedRewardsModel = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(getAllRewardsList.pending, (state) => {
            state.loading = true

        })
        builder.addCase(getAllRewardsList.fulfilled, (state, action) => {
            state.rewardsModelList = action.payload.data
            state.total = action.payload.count
            state.loading = false
        })
        builder.addCase(addRewardsData.fulfilled, (state, action) => {
            state.rewardsModelList = [...state.rewardsModelList, action.payload.data]
            state.selectedRewardsModel = action.payload.data
            state.message = action.payload?.message
            state.loading = false
        })
        builder.addCase(addRewardsData.rejected, (state, action) => {
          state.message = action.payload?.message
          state.loading = false
           
        })
        builder.addCase(editRewardsData.fulfilled, (state, action) => {
          for (const i in state.rewardsModelList) {
            if (state.rewardsModelList[i].id === action.payload.data.id) {
              state.rewardsModelList[i] = action.payload.data
              break
            }
          }
          state.selectedRewardsModel = action.payload.data
          state.message = action.payload?.message
          state.loading = false
        })
        builder.addCase(editRewardsData.rejected, (state, action) => {
          state.message = action.payload?.message
          state.loading = false
           
        })
        builder.addCase(delRewardsData.fulfilled, (state, action) => {
          state.rewardsModelList = state.rewardsModelList.filter(item => item.id !== action.payload.data.id)
          state.selectedRewardsModel = null
          state.message = action.payload?.message
          state.loading = false
        })
        builder.addCase(delRewardsData.rejected, (state, action) => {
          state.message = action.payload?.message
          state.loading = false
           
        })
    }
})

export const { setSelectedRewardsModel } = rewardsModelSlice.actions

export default rewardsModelSlice.reducer