// ** Reducers Imports
import layout from './layout'
import navbar from './navbar'
import auth from './authentication'
import driver from './../reducer/driver'
import vehicleModel from './../reducer/vehicleModel'
import vehicleMaker from './../reducer/vehicleMaker'
import vehicleType from './../reducer/vehicleType'
import zoneMapping from '../reducer/zoneMapping'
import supportChat from './../reducer/supportChat'
import color from './../reducer/color'
import state from './../reducer/state'
import rider from './../reducer/rider'
import advt from './../reducer/advt'
import userServiceFee from './../reducer/userServiceFee'
import globalSettings from './../reducer/globalSettings'
import registerAdmin from './../reducer/registerAdmin'
import rideListing from './../reducer/rideListing'
import promoCode from './../reducer/promoCode'
import stats from '../reducer/stats'
import userRepos from '../reducer/userRepos'
import rewards from '../reducer/rewards'
import zoneType from '../reducer/zoneType'
import stripe from '../reducer/stripe'
import autoSurge from '../reducer/autoSurge'
import assignDriver from '../reducer/assignDriver'

const rootReducer = {
  navbar,
  layout,
  auth,
  driver,
  rider,
  vehicleModel,
  vehicleMaker,
  vehicleType,
  zoneMapping,
  supportChat,
  color,
  state,
  advt,
  userServiceFee,
  globalSettings,
  registerAdmin,
  rideListing,
  promoCode,
  stats,
  userRepos,
  rewards,
  zoneType,
  stripe,
  autoSurge,
  assignDriver
}

export default rootReducer
