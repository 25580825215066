// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** UseJWT import to get config
// import useJwt from '@src/auth/jwt/useJwt'

// const config = useJwt.jwtConfig

const initialUser = () => {
    const item = window.localStorage.getItem('userData')
    //** Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : {}
}

export const authSlice = createSlice({
    name: 'authentication',
    initialState: {
        userData: initialUser()
    },
    reducers: {
        handleLogin: (state, action) => {
            state.userData = action.payload
            state["token"] = action.payload["token"]
            state["refreshToken"] = action.payload["token"]
            localStorage.setItem('userData', JSON.stringify(action.payload))
            localStorage.setItem("token", JSON.stringify(action.payload.token))
            localStorage.setItem("refreshToken", JSON.stringify(action.payload.token))
        },
        handleLogout: state => {
            state.userData = {}
            state["token"] = null
            state["refreshToken"] = null
            // ** Remove user, accessToken & refreshToken from localStorage
            localStorage.removeItem('userData')
            localStorage.removeItem("token")
            localStorage.removeItem("refreshToken")
        }
    }
})

export const { handleLogin, handleLogout } = authSlice.actions

export default authSlice.reducer
