// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAllAdvt, addAdvt, editAdvt, delAdvt  } from '../services/advertisement'

export const getAllAdvtList = createAsyncThunk('advtReducer/getAllAdvt', async params => {
  
    const response = await getAllAdvt(params)
    
    return { data: response.data, count: response.data?.length, loading: false, params, message: response?.message }
})

export const addAdvtData = createAsyncThunk('advtReducer/addAdvt', async data => {
    const response = await addAdvt(data)
    return { data: response.data, count: response?.data?.count, loading: false, message: response?.message }
})
export const editAdvtData = createAsyncThunk('advtReducer/editAdvt', async data => {
    const response = await editAdvt(data)
    return { data: response.data, count: response?.data?.count, loading: false, message: response?.message }
})
export const delAdvtData = createAsyncThunk('advtReducer/delAdvt', async data => {

    const response = await delAdvt(data)
 
    return { data, count: response?.data?.count, loading: false, message: response?.message }
})


export const advtModelSlice = createSlice({
    name: 'advtModel',
    initialState: {
        advtModelList: [],
        total: 0,
        selectedAdvtModel: null,
        message: null,
        loading: false
    },
    reducers: {
        setSelectedAdvtModel(state, action) {
            state.selectedAdvtModel = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(getAllAdvtList.pending, (state) => {
            state.loading = true

        })
        builder.addCase(getAllAdvtList.fulfilled, (state, action) => {
            state.advtModelList = action.payload.data
            state.total = action.payload.count
            state.loading = false
        })
        builder.addCase(addAdvtData.fulfilled, (state, action) => {
            state.advtModelList = [...state.advtModelList, action.payload.data]
            state.selectedAdvtModel = action.payload.data
            state.message = action.payload?.message
            state.loading = false
        })
        builder.addCase(addAdvtData.rejected, (state, action) => {
            state.message = action.payload?.message
            state.loading = false

        })
        builder.addCase(editAdvtData.fulfilled, (state, action) => {
            for (const i in state.advtModelList) {
                if (state.advtModelList[i]._id === action.payload.data._id) {
                    state.advtModelList[i].name = action.payload.data.name
                    state.advtModelList[i].type = action.payload.data.type
                    state.advtModelList[i].url = action.payload.data.url
                    state.advtModelList[i].isActive = action.payload.data.isActive
                    state.advtModelList[i].createdAt = action.payload.data.createdAt
                    state.advtModelList[i].updatedAt = action.payload.data.updatedAt
                    break
                }
            }
            state.selectedAdvtModel = action.payload.data
            state.message = action.payload?.message
            state.loading = false

        })
        builder.addCase(delAdvtData.pending, (state, action) => {
            state.message = action.payload?.message
            state.loading = true

        })
        builder.addCase(delAdvtData.fulfilled, (state, action) => {
            state.advtModelList = state.advtModelList.filter((item) => item._id !== action.payload.data._id)
            state.selectedAdvtModel = action.payload.data
            state.message = action.payload?.message
            state.loading = false

        })

    }
})

export const { setSelectedAdvtModel } = advtModelSlice.actions

export default advtModelSlice.reducer
