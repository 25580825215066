import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {getBalanceHistory} from '../services/stripe'

export const getBalanceHistoryObj = createAsyncThunk('stripeReducer/getBalanceHistoryObj', async params => {
    const response = await getBalanceHistory(params)
    return {data: response?.data?.data, count: response?.data?.data?.length, loading: false, params}
})

export const stripeModelSlice = createSlice({
    name: 'stripeModel',
    initialState: {
        balanceHistory: [],
        total: 0,
        loading: false
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getBalanceHistoryObj.pending, (state) => {
            state.loading = true

        })
        builder.addCase(getBalanceHistoryObj.fulfilled, (state, action) => {
            state.balanceHistory = action.payload.data
            state.total = action.payload.count
            state.loading = false
        })
        builder.addCase(getBalanceHistoryObj.rejected, (state) => {
            state.loading = false

        })
    }           
})

export default stripeModelSlice.reducer