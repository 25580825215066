import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { assignDriver } from '../services/assignDriver'

export const assignDriverToRide = createAsyncThunk('assignDriver/assignDriverToRide', async data => {
    const response = await assignDriver(data)
    return { data: response.data,  message: response.message, loading: false }
})

export const assignDriverSlice = createSlice({
    name: 'assignDriver',
    initialState: {
        assignDriver: {},
        total: 0,
        selectedZone: null,
        message: null,
        loading: false
    },
    reducers: {
        setSelectedZone(state, action) {
            state.selectedZone = action.payload
        }
    },
    extraReducers: builder => { 
        builder.addCase(assignDriverToRide.pending, (state) => {
            state.loading = true

        })
        builder.addCase(assignDriverToRide.fulfilled, (state, action) => {
            state.assignDriver = action.payload.data
            state.message = action.payload.message
            state.loading = false
        })
        builder.addCase(assignDriverToRide.rejected, (state) => {
            state.loading = false
        })
    }
})

export const { setSelectedZone } = assignDriverSlice.actions

export default assignDriverSlice.reducer
