import { baseApiCall } from "."

export const getBalanceHistory = (params) => {
    return baseApiCall({
        url: "/payment/balance-history",
        method: "post",
        params
    })
}

