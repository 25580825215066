import { baseApiCall } from "."


export const getAllAdvt = (params) => {
    return baseApiCall({
        url: "advertisement",
        method: "get",
        params
    })
}

export const addAdvt = (data) => {
    return baseApiCall({
        url: "advertisement",
        method: "post",
        data
    })
}

export const editAdvt = (data) => {
    const {
        name,
        type,
        url,
        _id
    } = data
    const editData = { name, type, url}
    return baseApiCall({
        url: (`advertisement/${_id}`),
        method: "put",
        data: editData
    })
}

export const delAdvt = (data) => {
    return baseApiCall({
        url: `advertisement/${data._id}`,
        method: "delete",
        data
    })
}
