import { baseApiCall } from "."


export const getAllZoneTypes = (params) => {
    return baseApiCall({
        url: "geo-fence/zone-type/listing",
        method: "get",
        params
    })
}

export const addZoneType = (data) => {
    return baseApiCall({
        url: "geo-fence/zone-type/create",
        method: "post",
        data
    })
}

export const editZoneType = (data) => {
    const {
       name,
       fillColor,
        _id
    } = data
    const editData = { name, fillColor}
    return baseApiCall({
        url: (`geo-fence/zone-type/${_id}`),
        method: "put",
        data: editData
    })
}

export const delZoneType = (data) => {
    return baseApiCall({
        url: `geo-fence/zone-type/${data._id}`,
        method: "delete",
        data
    })
}
