import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getUserRepos, notifyUser, notifyMultiUser } from '../services/userRepos'

export const getAllUserList = createAsyncThunk('userRepos/getAllUserList', async params => {
    const response = await getUserRepos(params)
    return { data: response.data, count:response.data?.length,  loading: false, params }
})
export const pushNotify = createAsyncThunk('userRepos/pushNotify', async data => {
    console.log('first test data here in reducer', data)
    const response = await notifyUser(data)
    return { data: response.data, message: response?.message,  loading: false, params }
})
export const pushMultiNotify = createAsyncThunk('userRepos/pushMultiNotify', async data => {
    console.log('first test data here in reducer', data)
    const response = await notifyMultiUser(data)
    return { data: response.data, message: response?.message,  loading: false, params }
})

export const userReposModelSlice = createSlice({
    name: 'userReposModel',
    initialState: {
        userReposModelList: [],
        notifyResponse: {},
        total: 0,
        message: '',
        selectedUserReposModel: null,
        loading: false
    },
    reducers: {
        setSelectedUserReposModel(state, action) {
            state.selectedUserReposModel = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(getAllUserList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getAllUserList.fulfilled, (state, action) => {
            state.userReposModelList = action.payload.data
            state.total = action.payload.count
            state.loading = false
        })
        builder.addCase(pushNotify.pending, (state) => {
            state.loading = true
        })
        builder.addCase(pushNotify.fulfilled, (state, action) => {
            state.notifyResponse = action.payload.data
            state.message = action.payload.message
            state.loading = false
        })
        builder.addCase(pushNotify.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(pushMultiNotify.pending, (state) => {
            state.loading = true
        })
        builder.addCase(pushMultiNotify.fulfilled, (state, action) => {
            state.notifyResponse = action.payload.data
            state.message = action.payload.message
            state.loading = false
        })
        builder.addCase(pushMultiNotify.rejected, (state) => {
            state.loading = false
        })


    }   
})

export const { setSelectedUserReposModel } = userReposModelSlice.actions
export default userReposModelSlice.reducer