import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { upImage, getVehicleTypes, createVehicleTypes, editVehicleTypes, delVehicleTypes } from '../services/vehicleType'


export const getUpImage = createAsyncThunk('vehicleType/upImage', async formData => {
    const response = await upImage(formData)
    return { data: response.data, loading: false }
})


export const getVehicleTypeList = createAsyncThunk('vehicleType/getList', async params => {
    const response = await getVehicleTypes(params)
    return { data: response.data, count: response.data.length, loading: false, params }
})

export const addVehicleType = createAsyncThunk('vehicleType/createVehicle', async data => {
    const response = await createVehicleTypes(data)
    return { data: response.data, loading: false }
})

export const updateVehicleType = createAsyncThunk('vehicleType/updateVehicle', async data => {
    const response = await editVehicleTypes(data)
    return { data: response.data, loading: false }
})

export const deleteVehicleType = createAsyncThunk('vehicleType/delVehicle', async data => {
    const response = await delVehicleTypes(data)
    return { data: response.data, loading: false }
})

export const vehicleTypeSlice = createSlice({
    name: 'vehicleType',
    initialState: {
        vehicleTypeList: [],
        total: 0,
        loading: false,
        selectedVehicleType: null,
        upImageUrl: null
    },
    reducers: {
        setSelectedVehicleType(state, action) {
            state.selectedVehicleType = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(getUpImage.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getUpImage.fulfilled, (state, action) => {
            state.upImageUrl = action.payload.data
            state.loading = false
        })
        builder.addCase(getVehicleTypeList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getVehicleTypeList.fulfilled, (state, action) => {
            state.vehicleTypeList = action.payload.data
            state.total = action.payload.count
            state.loading = false

        })
        builder.addCase(addVehicleType.pending, (state) => {
            state.loading = true
        })
        builder.addCase(addVehicleType.fulfilled, (state, action) => {
            state.vehicleTypeList = [...state.vehicleTypeList, action.payload.data]
            state.total = state.total + 1
            state.selectedVehicleType = action.payload.data
            state.loading = false
        })
        builder.addCase(addVehicleType.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(updateVehicleType.pending, (state) => {
            state.loading = false
        })
        builder.addCase(updateVehicleType.fulfilled, (state, action) => {
            for (const i in state.vehicleTypeList) {
                if (state.vehicleTypeList[i]._id === action.payload.data._id) {
                    state.vehicleTypeList[i].name = action.payload.data.name
                    state.vehicleTypeList[i].icon = action.payload.data.icon
                    state.vehicleTypeList[i].costForKm = action.payload.data.costForKm
                    state.vehicleTypeList[i].baseFare = action.payload.data.baseFare
                    state.vehicleTypeList[i].timeFarePerMin = action.payload.data.timeFarePerMin
                    state.vehicleTypeList[i].minFareAmount = action.payload.data.minFareAmount
                    state.vehicleTypeList[i].isActive = action.payload.data.isActive
                    break
                }
            }

            state.selectedVehicleType = action.payload.data
            state.loading = false
        })
        builder.addCase(updateVehicleType.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(deleteVehicleType.pending, (state) => {
            state.loading = true
        })
        builder.addCase(deleteVehicleType.fulfilled, (state, action) => {
            state.vehicleTypeList = state.vehicleTypeList.filter(item => action.payload.data._id !== item._id)
            state.selectedVehicleType = action.payload.data
            state.total = state.total - 1
            state.loading = false
        })
        builder.addCase(deleteVehicleType.rejected, (state) => {
            state.loading = false
        })


    }
})


export const { setSelectedVehicleType } = vehicleTypeSlice.actions

export default vehicleTypeSlice.reducer
