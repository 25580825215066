import { baseApiCall } from "."

export const fetchRider = (params) => {
    return baseApiCall({
        url: "admin/users",
        method: "get",
        params
    })
}

export const fetchRiderDetails = (params) => {
    return baseApiCall({
        url: "admin/user",
        method: "get",
        params
    })
}

export const updateRiderInfo = (data) => {
    return baseApiCall({
        url: "admin/update-user",
        method: "post",
        data
    })
}

