import { baseApiCall } from "."

export const upImage = (formData) => {
    return baseApiCall({
        url: "image/upload/",
        method: "post",
        data: formData
        
    })
}

export const getVehicleTypes = (params) => {
    return baseApiCall({
        url: "vehicle-type",
        method: "get",
        params
    })
}
export const createVehicleTypes = (data) => {
    return baseApiCall({
        url: "vehicle-type",
        method: "post",
        data
    })
}

export const editVehicleTypes = (data) => {
    const {
        isActive,
        baseFare,
        costForKm,
        icon,
        name,
        timeFarePerMin,
        minFareAmount,
        _id
    } = data
    const editData = { isActive, baseFare, costForKm, icon, name, minFareAmount, timeFarePerMin }
    return baseApiCall({
        url: (`vehicle-type/${_id}`),
        method: "put",
        data: editData
    })
}

export const delVehicleTypes = (data) => {
    return baseApiCall({
        url: (`vehicle-type/${data._id}`),
        method: "delete",
        data

    })
}