import { baseApiCall } from "."

export const getRideList = (params) => {
    return baseApiCall({
        url: "admin/rides/list",
        method: "get",
        params
    })
}

export const assignDriver = (data) => {
    return baseApiCall({
        url: "admin/rides/list",
        method: "post",
        data
    })
}

