import toast from "react-hot-toast"
import { apiInstant } from "../configs/api"
import { handleLogout } from "../redux/authentication"
import { store } from "./../redux/store"

export const baseApiCall = (config) => {
    return new Promise((resolve, reject) => {
        apiInstant(config).then(res => {

            resolve(res.data)
        }).catch(err => {
            if (err.response && err.response.data) {
                console.log('from_service_index.js', err.response.data)
                if (err.response.status === 401) {
                    store.dispatch(handleLogout())
                    window.location.href = '/login'
                    // return redirect('/login')
                }
                toast.error(err.response.data.message)
            }
            reject(err)
        })
    })
}