import { baseApiCall } from "."

export const getAdminUser = (params) => {
    return baseApiCall({
        url: "admin/admin-users",
        method: "get",
        params
    })
}

export const createAdminUser = (data) => {
    return baseApiCall({
        url: "admin/register",
        method: "post",
        data
    })
}

export const updateAdminUser = (data) => {
    return baseApiCall({
        url: `admin/update-admin/${data._id}`,
        method: "put",
        data: editData
    })
}

export const deleteAdminUser = (data) => {
    return baseApiCall({
        url: `admin/delete-admin/${data}`,
        method: "delete",
        data
    })
}