import { baseApiCall } from "."


export const getAllRewards = (params) => {
    return baseApiCall({
        url: "rewards/all-rewards",
        method: "get",
        params
    })
}

export const addRewards = (data) => {
    return baseApiCall({
        url: "rewards/create-reward",
        method: "post",
        data
    })
}

export const editRewards = (data) => {
    const {
       name,
       hexCode,
        _id
    } = data
    const editData = { name, hexCode}
    return baseApiCall({
        url: (`rewards/${_id}`),
        method: "put",
        data: editData
    })
}

export const delRewards = (data) => {
    return baseApiCall({
        url: `rewards/${data._id}`,
        method: "delete",
        data
    })
}
