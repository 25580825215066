import { baseApiCall } from "."


export const getAllZones = (params) => {
    return baseApiCall({
        url: "geo-fence/get-all-zones",
        method: "get",
        params
    })
}

export const addZoneArea = (data) => {
    return baseApiCall({
        url: "geo-fence/create-zone",
        method: "post",
        data
    })
}

export const editZoneArea = (data) => {
    const {
       name,
       area,
       type,
        _id
    } = data
    const editData = { name, area, type}
    return baseApiCall({
        url: (`geo-fence/zones/${_id}`),
        method: "put",
        data: editData
    })
}


export const delZoneArea = (data) => {
    return baseApiCall({
        url: `geo-fence/zones/${data._id}`,
        method: "delete",
        data
    })
}
