// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { addState, editState, delState, getAllStates } from '../services/state'

export const getAllStatesList = createAsyncThunk('stateReducer/getAllStates', async params => {
    const response = await getAllStates(params)
    return { data: response.data, count: response.data?.length, loading: false, params }
})

export const addStateData = createAsyncThunk('stateReducer/addState', async data => {
    const response = await addState(data)
    return { data: response.data, count: response.data?.length, loading: false, message: response?.message }
})
export const editStateData = createAsyncThunk('stateReducer/editState', async (data) => {
  const response = await editState(data)
  return { data: response.data, count: response.data?.length, loading: false, message: response?.message }
})
export const delStateData = createAsyncThunk('stateReducer/delState', async data => {
  const response = await delState(data)
  return { data: response.data, count: response.data?.length, loading: false, message: response?.message }
})


export const stateModelSlice = createSlice({
    name: 'stateModel',
    initialState: {
        stateModelList: [],
        total: 0,
        selectedStateModel: null,
        message: null,
        loading: false
    },
    reducers: {
        setSelectedStateModel(state, action) {
            state.selectedStateModel = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(getAllStatesList.pending, (state) => {
            state.loading = true

        })
        builder.addCase(getAllStatesList.fulfilled, (state, action) => {
            state.stateModelList = action.payload.data
            state.total = action.payload.count
            state.loading = false
        })
        builder.addCase(addStateData.fulfilled, (state, action) => {
            state.stateModelList = [...state.stateModelList, action.payload.data]
            state.selectedStateModel = action.payload.data
            state.message = action.payload?.message
            state.loading = false
        })
        builder.addCase(addStateData.rejected, (state, action) => {
          state.message = action.payload?.message
          state.loading = false
           
        })
        builder.addCase(editStateData.fulfilled, (state, action) => {
          for (const i in state.stateModelList) {
            if (state.stateModelList[i]._id === action.payload.data._id) {
                state.stateModelList[i].name = action.payload.data.name
                state.stateModelList[i].country = action.payload.data.country
                state.stateModelList[i].isActive = action.payload.data.isActive
                state.stateModelList[i].createdAt = action.payload.data.createdAt
                state.stateModelList[i].updatedAt = action.payload.data.updatedAt
                break
            }
        }
          state.selectedStateModel = action.payload.data
          state.message = action.payload?.message
          state.loading = false
           
        })
        builder.addCase(delStateData.pending, (state, action) => {
          state.message = action.payload?.message
          state.loading = false
           
        })
        builder.addCase(delStateData.fulfilled, (state, action) => {
          state.stateModelList = state.stateModelList.filter(item => action.payload.data._id !== item._id)
          state.selectedStateModel = action.payload.data
          state.message = action.payload?.message
          state.loading = false
           
        })

    }
})

export const { setSelectedStateModel } = stateModelSlice.actions

export default stateModelSlice.reducer
