import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAdminUser, createAdminUser, updateAdminUser, deleteAdminUser } from '../services/registerAdmin'

export const getAdminUserList = createAsyncThunk('adminUser/getAdminUser', async params => {
    console.log('params', params)
    const response = await getAdminUser(params)
    console.log('response', response)
    return { data: response.data.data, count: response.data?.length, loading: false, params }
})


export const addAdminUserData = createAsyncThunk('adminUser/addAdminUserData', async data => {
    const response = await createAdminUser(data)
    return { data: response.data,  loading: false, message: response?.message }
})

export const updateAdminUserData = createAsyncThunk('adminUser/updateAdminUserData', async data => {
    const response = await updateAdminUser(data)
    return { data: response.data,  loading: false, message: response?.message }
})

export const delAdminUserData = createAsyncThunk('adminUser/delAdminUserData', async data => {
    console.log('reducer', data)
    const response = await deleteAdminUser(data)
    console.log('first', response)
    return { data,  loading: false, message: response?.message }
})

export const adminUserSlice = createSlice({
    name: 'adminUser',
    initialState: {
        adminUserList: [],
        total: 0,
        selectedAdminUser: null,
        message: null,
        loading: false
    },
    extraReducers: builder => {
        builder.addCase(getAdminUserList.pending, (state) => {
            state.loading = true

        })
        builder.addCase(getAdminUserList.fulfilled, (state, action) => {
            state.adminUserList = action.payload.data
            state.total = action.payload.count
            state.loading = false
        })
        builder.addCase(addAdminUserData.pending, (state) => {
            state.loading = true

        })
        builder.addCase(addAdminUserData.fulfilled, (state, action) => {
            state.adminUserList = [...state.adminUserList, action.payload.data] 
            state.selectedAdminUser = action.payload.data
            state.message = action.payload?.message
            state.total =  state.total + 1
            state.loading = false
        })
        builder.addCase(addAdminUserData.rejected, (state, action) => {
            state.message = action.payload?.message
            state.loading = false
           
        })
        builder.addCase(updateAdminUserData.pending, (state) => {
            state.loading = true

        })
        builder.addCase(updateAdminUserData.fulfilled, (state, action) => {
            state.adminUserList = state.adminUserList.map(item => {
                if (item.id === action.payload.data.id) {
                    return action.payload.data
                }
                return item
            })
            state.selectedAdminUser = action.payload.data
            state.message = action.payload?.message
            state.loading = false
        })
        builder.addCase(updateAdminUserData.rejected, (state, action) => {
            state.message = action.payload?.message
            state.loading = false
           
        })
        builder.addCase(delAdminUserData.pending, (state) => {
            state.loading = true

        })
        builder.addCase(delAdminUserData.fulfilled, (state, action) => {
            state.adminUserList = state.adminUserList.filter(item => action.payload.data !== item.id)
            state.loading = false
        })
        builder.addCase(delAdminUserData.rejected, (state, action) => {
            state.message = action.payload?.message
            state.loading = false
           
        })

    }
})

export const { setSelectedAdminUser } = adminUserSlice.actions

export default adminUserSlice.reducer

