import { baseApiCall } from "."


export const getAutoSurge = (params) => {
    return baseApiCall({
        url: "geo-fence/admin/auto-surge-zones",
        method: "get",
        params
    })
}
export const addStreakReward = (data) => {
    return baseApiCall({
        url: "streak",
        method: "post",
        data
    })
}
export const getStreakReward = (params) => {
    return baseApiCall({
        url: "streak",
        method: "get",
        params
    })
}
export const updateStreakReward = (data) => {
    const { id, ...rest } = data
    return baseApiCall({
        url: `streak/${id}`,
        method: "put",
        data: {...rest}
    })
}

export const assignSurgeValue = (data) => {
    return baseApiCall({
        url: "geo-fence/auto-surge-mapping-zone",
        method: "post",
        data
    })
}

export const getAssignSurge = (data) => {
    return baseApiCall({
        url: "geo-fence/auto-surge-mapping-zone",
        method: "get",
        data
    })
}