// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { updateDriverStatus } from '../services/driver'
import { fetchRider, fetchRiderDetails, updateRiderInfo } from '../services/rider'

export const getRiderList = createAsyncThunk('rider/getList', async params => {
    const response = await fetchRider(params)
    return { data: response.data.data, count: response.data.count, params }
})

export const getRiderById = createAsyncThunk('rider/getDetails', async params => {

    const response = await fetchRiderDetails(params)
    return { data: response.data }
})

export const saveRiderInfo = createAsyncThunk('rider/saveInfo', async data => {
    const response = await updateRiderInfo(data)
    return { data: response.data }
})

export const changeRiderStatus = createAsyncThunk('rider/changeStatus', async data => {
    console.log('first', data)
    const response = await updateDriverStatus(data)
    return { data: response.data }
})

export const riderSlice = createSlice({
    name: 'rider',
    initialState: {
        riderList: [],
        total: 0,
        loading: false,
        selectedUser: null
    },
    reducers: {
        setSelectedUser(state, action) {
            state.selectedUser = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(getRiderList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getRiderList.fulfilled, (state, action) => {
            state.riderList = action.payload.data
            state.total = action.payload.count
            state.loading = false
        })
        builder.addCase(getRiderById.fulfilled, (state, action) => {
            state.selectedUser = action.payload.data
            state.loading = false
        })
        builder.addCase(saveRiderInfo.fulfilled, (state, action) => {
            state.selectedUser = action.payload.data
            state.loading = false
        })
        builder.addCase(changeRiderStatus.pending, (state) => {
            state.loading = true
        })
        builder.addCase(changeRiderStatus.fulfilled, (state, action) => {
            state.selectedUser.status = action.meta.arg.status
        })
        builder.addCase(changeRiderStatus.rejected, (state) => {
            state.loading = false
        })
     
    }
})

export const { setSelectedUser } = riderSlice.actions

export default riderSlice.reducer
