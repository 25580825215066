import { baseApiCall } from "."


export const getVehicleModels = (params) => {
    return baseApiCall({
        url: "vehicleModel",
        method: "get",
        params
    })
}

export const addVehicleModels = (data) => {
    return baseApiCall({
        url: "vehicleModel",
        method: "post",
        data
    })
}

