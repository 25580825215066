import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {getGenStats, getDriverStats, getRiderStats } from '../services/stats'


export const getGenStatsObj = createAsyncThunk('statsReducer/getGenStatsObj', async params => {
    const response = await getGenStats(params)
    return { data: response.data, loading: false, params }
})

export const getDriverStatsObj = createAsyncThunk('statsReducer/getDriverStatsObj', async params => {
    const response = await getDriverStats(params)
    return { data: response.data, loading: false, params }
})

export const getRiderStatsObj = createAsyncThunk('statsReducer/getRiderStatsObj', async params => {
    const response = await getRiderStats(params)
    return { data: response.data, loading: false, params }
})

export const statsModelSlice = createSlice({
    name: 'statsModel',
    initialState: {
        genStats: {},
        driverStats: {},
        riderStats: {},
        loading: false
    },
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(getGenStatsObj.pending, (state) => {
            state.loading = true

        })
        builder.addCase(getGenStatsObj.fulfilled, (state, action) => {
            state.genStats = action.payload.data
            state.loading = false
        })
        builder.addCase(getDriverStatsObj.pending, (state) => {
            state.loading = true

        })
        builder.addCase(getDriverStatsObj.fulfilled, (state, action) => {
            state.driverStats = action.payload.data
            state.loading = false
        })
        builder.addCase(getRiderStatsObj.pending, (state) => {
            state.loading = true

        })
        builder.addCase(getRiderStatsObj.fulfilled, (state, action) => {
            state.riderStats = action.payload.data
            state.loading = false
        })
    }   
})

export default statsModelSlice.reducer