import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAllZones, addZoneArea, editZoneArea, delZoneArea } from '../services/zoneMapping'


export const getAllZoneAreaList = createAsyncThunk('zoneMapping/getAllZoneAreaList', async params => {
    const response = await getAllZones(params)
    return { data: response.data, count: response.data?.length, loading: false, params }
})

export const createZoneArea = createAsyncThunk('zoneMapping/createZoneArea', async data => {
    console.log('create zone area', data)
    const response = await addZoneArea(data)
    return { data: response.data, count: response?.data?.length, loading: false, message: response?.message }
})

export const updateZoneArea = createAsyncThunk('zoneMapping/updateZoneArea', async data => {
    const response = await editZoneArea(data)
    return { data: response.data, count: response?.data?.length, loading: false, message: response?.message }
})


export const deleteZoneArea = createAsyncThunk('zoneMapping/deleteZoneArea', async data => {
    const response = await delZoneArea(data)
    return { data: response.data, count: response?.data?.length, loading: false, message: response?.message }
})


export const zoneAreaSlice = createSlice({
    name: 'zoneMapping',
    initialState: {
        zoneAreaList: [],
        total: 0,
        selectedZone: null,
        message: null,
        loading: false
    },  
    reducers: {
        setSelectedZone(state, action) {
            state.selectedZone = action.payload
        }   
    },
    extraReducers: builder => {
        builder.addCase(getAllZoneAreaList.pending, (state) => {
            state.loading = true

        })
        builder.addCase(getAllZoneAreaList.fulfilled, (state, action) => {
            state.zoneAreaList = action.payload.data
            state.total = action.payload.count
            state.loading = false
        })
        builder.addCase(createZoneArea.pending, (state, action) => {
            state.message = action.payload?.message
            state.loading = true
        })
        builder.addCase(createZoneArea.fulfilled, (state, action) => {
            for (const i in state.zoneAreaList) {
                if (state.zoneAreaList[i]._id === action.payload.data._id) {
                    state.zoneAreaList[i].name = action.payload.data.name
                    state.zoneAreaList[i].area = action.payload.data.area
                    state.zoneAreaList[i].isActive = action.payload.data.isActive
                    state.zoneAreaList[i].createdAt = action.payload.data.createdAt
                    state.zoneAreaList[i].updatedAt = action.payload.data.updatedAt
                    break
                }
            }
            state.selectedZone = action.payload.data
            state.message = action.payload?.message
            state.loading = false
        })
        builder.addCase(createZoneArea.rejected, (state, action) => {
            state.message = action.payload?.message
            state.loading = false
        })

        builder.addCase(updateZoneArea.fulfilled, (state, action) => {
            // for (const i in state.zoneAreaList) {
            //     if (state.zoneAreaList[i]._id === action.payload.data._id) {
            //         state.zoneAreaList[i].name = action.payload.data.name
            //         state.zoneAreaList[i].areaData = action.payload.data.areaData
            //         break
            //     }
            // }

            state.selectedZone = action.payload.data
            state.loading = false
        })
        builder.addCase(deleteZoneArea.pending, (state) => {
            state.loading = true

        })
        builder.addCase(deleteZoneArea.fulfilled, (state, action) => {
            state.zoneAreaList = state.zoneAreaList.filter(item => action.payload.data._id !== item._id)
            state.selectedZone = action.payload.data
            state.message = action.payload?.message
            state.loading = false
        })
        builder.addCase(deleteZoneArea.rejected, (state, action) => {
            state.loading = false
            state.message = action.payload?.message
        })

    }
})

export const { setSelectedZone } = zoneAreaSlice.actions

export default zoneAreaSlice.reducer