import { baseApiCall } from "."

export const getGenStats = (params) => {
    return baseApiCall({
        url: "/stats/gen-stats",
        method: "get",
        params
    })
}

export const getDriverStats = (params) => {
    return baseApiCall({
        url: "/stats/driver-stats",
        method: "get",
        params
    })
}

export const getRiderStats = (params) => {
    return baseApiCall({
        url: "/stats/rider-stats",
        method: "get",
        params
    })
}
