import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getRideList } from '../services/rideListing'


export const getRideListData = createAsyncThunk('getRideList/getRideListData', async params => {
    const response = await getRideList(params)
    console.log('ride response', response)
    
    return { data: response, count: response.length, loading: false }
})

export const rideListSlice = createSlice({
    name: 'rideList',
    initialState: {
        rideList: [],
        total: 0,
        loading: false,
        selectedRide: null
    },
    reducers: {
        setSelectedRide(state, action) {
            state.selectedRide = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(getRideListData.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getRideListData.fulfilled, (state, action) => {
            state.rideList = action.payload.data
            state.total = action.payload.count
            state.loading = false
        })
    }
})

export const { setSelectedRide } = rideListSlice.actions
export default rideListSlice.reducer