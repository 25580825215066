import { baseApiCall } from "."


export const getAllConfig = (params) => {
    return baseApiCall({
        url: "config",
        method: "get",
        params
    })
}

export const addAdminSettings = (data) => {
    return baseApiCall({
        url: "config/admin-settings",
        method: "post",
        data
    })
}


export const addAppSettings = (data) => {
    return baseApiCall({
        url: "config/app-settings",
        method: "post",
        data
    })
}

export const addServiceSettings = (data) => {
    return baseApiCall({
        url: "config/service-settings",
        method: "post",
        data
    })
}
export const riderReferral = (data) => {
    return baseApiCall({
        url: "config/rider-referral-settings",
        method: "post",
        data
    })
}
export const driverReferral = (data) => {
    return baseApiCall({
        url: "config/driver-referral-settings",
        method: "post",
        data
    })
}